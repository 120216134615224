import React from "react"
import { Helmet } from "react-helmet"
import "./bootstrap.css"
import "./index.css"

import HomePage from "./Home/HomePage"

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>SysDone</title>
      </Helmet>
      <HomePage />
    </div>
  )
}
